<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  function scollToWidget() {
    document.querySelector('#compatibilityWidget').scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
</script>

<Section verticalPadding="none">
  <h2 class="font-bold text-center mb-10">Frequently Asked Questions</h2>
  <div class="space-y-4">
    <p class="font-bold">Does FIXD work with my car?</p>
    <p>
      FIXD works with all gas-powered cars and trucks made after 1995, and
      diesel vehicles made after 2007. To ensure that FIXD will work with your
      car, <button on:click={scollToWidget} class="bare hover:cursor-pointer">
        <span class="underline">click here.</span></button
      >
    </p>

    <p class="font-bold">Is it easy to use?</p>

    <p>
      YES! We designed FIXD to be easy enough for anyone who drives. Setting up
      FIXD takes about 1 minute, and scanning your vehicle takes just seconds.
    </p>

    <p class="font-bold">
      I have more than one car. Do I need multiple FIXD sensors?
    </p>

    <p>
      While it's possible to take care of all of your cars with a single sensor,
      we recommend purchasing one sensor per vehicle.
    </p>

    <p>
      That way, you can monitor the health of your cars in real time from your
      smartphone - all in one easy-to-use app.
    </p>

    <p class="font-bold">What can FIXD tell me about my car?</p>

    <p>
      The FIXD sensor and free app can diagnose over 7,000 “check engine” codes
      and provide reports in plain English.
    </p>

    <p>
      It can also tell you how severe the problem is, so you know whether it's
      safe to keep driving.
    </p>

    <p class="font-bold">Is FIXD compatible with my smartphone?</p>

    <p>Yes! FIXD is compatible with all Android and Apple smartphones.</p>

    <p class="font-bold">Do I have to have a subscription?</p>

    <p>
      No. FIXD Classic is free for life once you purchase a FIXD sensor. If
      you'd like to upgrade and get confirmed estimates, access to expert
      mechanics, and much more, you can begin a 14-day FREE trial of FIXD
      Premium at no risk!
    </p>
  </div>

  <div class="mb-10 md:mb-20">
    <slot name="cta" />
  </div>
</Section>
